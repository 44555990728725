.blog-tag{
    display: inline-block;
    background-color: #C1CAE3;
    border-radius:8px;
    font-weight: 500;
    font-size: 1.25rem;
    color: #220756;
    padding:2px 12px;
}

.blog-tag:hover{
 color: white!important;
}

.back-btn{
    cursor: "pointer";
    width: 15px;
    height: 15px;
}


.blog-author{
    color:#000;
    font-weight: 400;
    font-size: 1.2rem;
    margin-bottom: 0;
  }

@media screen and (max-width:30em) {
    .blog-tag{
    padding:3px 11px;
    font-weight: 500;
    font-size: 0.75rem;
}

.back-btn{
    margin-top:-10px;
}

.blog-author{
    font-size: 1rem;
}
}