@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600;700;800;900&display=swap');


.blog-content {
  margin-top: 40px;
}

.blog-thumbnail {
  display: flex;
  margin:0 auto;
  margin-top: 3.125rem;
  width: min(80vw, 1201px);
  max-height: 599px;
  object-fit: cover;
}

.blog-content img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}


#blog-body {
  max-width: min(70vw,1201px);
  font-weight: 400;
  font-size: 1.125rem;
}

.blog-title {
  color: #220756;
  font-weight: 800;
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.blog-date {
  color: #666666;
  font-weight: 400;
  font-size: 1.2rem;
  margin-bottom: 0;
}

/* for mobile devices.. */
@media screen and (max-width:30em) {
  .blog-title{
    font-size: 1.5rem;
  }

  .blog-date{
    font-size: 1rem;
  }

  .blog-thumbnail {
    width: 85vw;
    max-height: 162px;
    object-fit: cover;
  }
  .blog-content img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50vw;
  height:30vh;
}

  #blog-body {
    max-width: 90vw;
  }
}