.col {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
}

a {
  text-decoration: none !important;
}
.cardBodyContainer {
  display: flex !important;
  flex-direction: row !important;
}
.cardBodyContent {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-evenly !important;
}
.decoration {
  margin-left: 450px;
  font-size: 18px;
}
.card-image-img {
  margin: 1rem !important;
  width: 100vw !important;
  max-width: 30vw !important;
  object-fit: cover;
}
.categoryLabel {
  margin: 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center !important;
}

.textBold {
  font-weight: bold !important;
}

.related-cards {
  /* height: 640px!important; */
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  flex: 1 0 20em;
}

.related__card-img {
  width: 500px;
  height: 310px;
  border-radius: 5px;
}

.blog-date__card,
.blog-author__card {
  font-size: 1.0625rem;
}

.flex-col {
  display: flex;
  flex-direction: column;
  flex: 1 0 20em;
}

@media (max-width: 1000px) {
  .decoration {
    margin-left: 50px;
  }
}

@media (max-width: 600px) {
  .related__card-img {
    max-width: 256px;
    max-height: 164px;
  }
  .cardBodyContainer {
    flex-direction: column !important;
  }
  .card-image-img {
    margin: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    /* height:40vh !important; */
  }
}
@media (max-width: 800px) {
  .col {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    height: max-content;
  }

  .categoryLabel{
    margin:0;
  }
}

@media (min-width: 601px) {
  .col {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

@media screen and (max-width: 30em) {
  .related-cards {
    height: fit-content !important;
    margin-right: 0px;
  }
}
