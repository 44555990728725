@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600;700;800;900&display=swap');

#signup-btn {
    color: white;
    background-color: #CC0066;
    width: 110px;
    height: 39px;
  }
  
  #login-btn {
    width: 110px;
    height: 39px;
    background-color: transparent;
    border: 1px solid #220756;
    margin-right: 16px;
  }
  
  .dropdown-toggle::after {
    content: none !important;
  }
  
#site-logo {
    width: min(30vw, 160px);
  }

.nav-link {
    color: #2D0D4F !important;
  }

  .nav-items{
    margin-right: 20px;
  }
  
@media screen and (max-width:30em) {
    #site-logo {
      width: min(30vw, 120px);
    }

    .nav-items{
      margin-right: 0;
    }
} 

