

.footer{
    background-color: #20374B;
}


.white-line{
    margin-top: 120px;
    margin-bottom: 30px;
    background-color: white;
    height: 1px;
    width: 100%;
}

h4{
    font-size: 18px;
    font-weight: bold;
}